<template>
  <div class="justify-center">
    <Matcher
      :i18n_instructionMsg_key="'matchColorssInstruction'"
      :matchList="match"
      :numberOfMatches="match.length"
    >
      <template v-slot:default="slotProps">
        <div
          class="text-center mx-auto"
          :style="
            slotProps.leftText.eng === 'white'
              ? { background: 'gray', padding: '1px', borderRadius: '7px' }
              : {}
          "
        >
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            :width="30"
            :height="30"
            viewBox="0 0 72 72"
            :style="{ position: 'relative', top: '3' }"
            preserveAspectRatio="xMidYMid meet"
            :fill="slotProps.leftText.eng"
          >
            <g
              transform="translate(0,72) scale(0.007660,-0.008531)"
              stroke="none"
            >
              <path
                d="M4400 8434 c-25 -2 -106 -8 -180 -14 -259 -21 -728 -93 -829 -127
            -68 -22 -137 -70 -151 -103 -9 -21 -10 -64 -5 -147 3 -65 10 -389 14 -720 6
            -418 12 -607 20 -616 6 -8 8 -16 5 -20 -3 -3 4 -41 16 -84 19 -69 22 -106 24
            -326 2 -136 0 -250 -4 -252 -4 -2 -30 8 -57 22 -46 25 -53 26 -92 15 -65 -20
            -77 -47 -73 -167 1 -55 10 -127 18 -160 9 -33 24 -125 34 -205 26 -209 38
            -268 86 -434 45 -158 74 -206 132 -221 32 -8 77 11 89 37 7 16 13 -2 26 -83
            10 -57 37 -161 60 -231 l44 -127 -22 -23 c-13 -13 -35 -48 -51 -78 -18 -35
            -66 -93 -133 -160 -114 -114 -159 -177 -168 -237 -3 -21 -14 -47 -23 -58 -10
            -11 -27 -39 -38 -61 l-21 -42 -113 -21 c-62 -11 -144 -25 -183 -31 -85 -13
            -363 -72 -500 -106 -179 -44 -347 -104 -501 -180 -96 -47 -188 -83 -250 -100
            -115 -30 -159 -51 -195 -91 -27 -29 -138 -220 -272 -468 -67 -124 -90 -181
            -154 -380 -8 -27 -35 -84 -60 -126 -62 -108 -129 -278 -227 -581 -90 -277
            -142 -399 -224 -520 -67 -100 -184 -309 -231 -411 -53 -114 -80 -208 -146
            -502 -31 -137 -58 -260 -61 -272 l-6 -23 4701 0 c2586 0 4701 3 4701 6 0 19
            -66 230 -100 323 -22 58 -48 141 -59 184 -21 85 -46 132 -116 222 l-47 60 8
            100 c5 55 9 179 9 275 l0 175 -41 85 c-95 195 -120 278 -154 508 -38 263 -70
            393 -118 489 -20 39 -137 182 -296 361 -67 77 -74 88 -79 136 -6 59 -41 136
            -82 179 -39 41 -95 74 -209 122 -55 23 -167 74 -247 113 -224 108 -312 136
            -530 172 -68 12 -158 30 -199 40 -41 10 -154 35 -250 54 -251 50 -280 58 -280
            76 0 9 -16 30 -35 49 -19 18 -35 39 -35 47 0 23 -31 114 -41 120 -5 3 -9 14
            -9 24 0 10 -9 25 -20 33 -11 7 -40 45 -64 83 -62 97 -93 132 -137 155 -22 11
            -39 27 -39 36 0 9 -19 35 -43 57 -27 25 -48 57 -55 82 -6 22 -23 54 -37 70
            -21 24 -25 39 -25 91 0 59 -15 113 -31 113 -4 0 -17 21 -30 48 -12 26 -35 61
            -52 79 l-29 31 18 47 c15 41 19 44 25 27 17 -45 85 -55 156 -23 91 42 172 159
            244 351 23 63 49 122 56 132 18 20 55 129 90 262 23 86 27 119 25 223 -2 136
            -9 157 -67 193 -62 38 -141 34 -256 -14 -12 -5 -18 0 -24 21 -5 22 -18 262
            -16 308 9 216 9 278 0 302 -8 19 -11 165 -9 468 1 241 3 442 5 446 2 3 5 149
            6 324 l1 319 -23 19 c-119 99 -463 165 -1104 212 -210 16 -571 26 -655 19z"
              />
            </g>
          </svg>
        </div>
      </template>
    </Matcher>
  </div>
</template>

<script>
import Matcher from './Matcher'

export default {
  components: {
    Matcher
  },

  mounted() {},

  methods: {},
  data: () => ({
    match: [
      { eng: 'red', guj: 'લાલ', hindi: 'red', index: 1 },
      { eng: 'yellow', guj: 'પીળો', hindi: 'yellow', index: 2 },
      { eng: 'black', guj: 'કાળો', hindi: 'black', index: 3 },
      { eng: 'skyblue', guj: 'વાદળી', hindi: 'skyblue', index: 4 },
      { eng: 'green', guj: 'લીલો', hindi: 'green', index: 1 },
      { eng: 'darkorange', guj: 'નારંગી', hindi: 'darkorange', index: 5 },
      { eng: 'deeppink', guj: 'ગુલાબી', hindi: 'deeppink', index: 6 },
      { eng: 'saddlebrown', guj: 'કથ્થઈ', hindi: 'saddlebrown', index: 7 },
      { eng: 'lightgrey', guj: 'ચાંદી', hindi: 'lightgrey', index: 8 },
      { eng: 'blueviolet', guj: 'જાંબલી', hindi: 'blueviolet', index: 9 },
      { eng: 'white', guj: 'સફેદ', hindi: 'white', index: 1 }
    ]
  })
}
</script>
<style></style>
