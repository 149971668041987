<template>
  <v-container
    class="justify-center pt-0"
    :style="{ maxWidth: $vuetify.breakpoint.smAndUp ? '900px' : '600px' }"
  >
    <div class="text-center mb-4">
      <HowToPlay :i18n_instructions_key="i18n_instructionMsg_key" />
    </div>
    <v-row class="justify-center ma-1">
      <v-col xl="4" xs="3" class="mr-0 pr-0">
        <v-row
          v-for="(w1, j) in array1"
          :key="'array1' + j"
          :style="{ fontSize: fontSize }"
          class="word row-v mr-0 pr-1 pl-2"
          justify="center"
          align="center"
          :ref="'array1' + j"
        >
          <slot v-bind:leftText="array1[j]">
            {{ array1[j][selectedLanguage] }}
          </slot>
        </v-row>
      </v-col>
      <v-col xl="4" xs="3" class="ml-1 pl-0">
        <draggable
          :list="array2"
          :disabled="!enabled"
          class="justify-space-around"
          ghost-class="ghost"
          @start="dragging = true"
          @end="endDragging()"
          v-bind="dragOptions"
        >
          <transition-group type="transition" name="flip-list">
            <v-row
              v-for="(w, j) in array2"
              :key="'array2' + j"
              :style="{ fontSize: fontSize }"
              class="word word-drgabble ml-0 pl-2 pr-1"
              justify="center"
              align="center"
              :ref="'array2' + j"
            >
              {{ w.guj }}
            </v-row>
          </transition-group>
        </draggable>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-10">
      <v-btn color="secondary" @click="tryAgain()" raised="true">
        <span>{{ $t('Try Again') }}</span>
      </v-btn>
    </v-row>

    <GoBackButton />
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import GoBackButton from './GoBackButton'
import Utils from '../util/Utils.js'
import HowToPlay from './HowToPlay.vue'

export default {
  props: {
    matchList: { type: Array, required: true },
    i18n_instructionMsg_key: { type: String, required: true },
    numberOfMatches: { type: Number, default: 10 },
    fontSize: { type: String, default: '16px' },
    showLangOption: { type: Boolean, default: true }
  },

  components: {
    draggable,
    GoBackButton,
    HowToPlay
  },

  watch: {
    // disable all buttons on game over; re-enable all buttons on restart
    matchList: function (newValue) {
      this.refresh()
    }
  },
  computed: {
    selectedLanguage() {
      if (this.$store.state.selectedLanguage == 'guj') {
        return 'eng'
      } else {
        return this.$store.state.selectedLanguage
      }
    },

    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  updated: function () {
    this.calculateMaxHeight()
  },
  mounted: function () {
    this.calculateMaxHeight()
  },
  created: function () {
    this.refresh()
  },
  methods: {
    calculateMaxHeight() {
      Object.keys(this.$refs).forEach((k) => {
        this.$refs[k][0].style.removeProperty('height')
      })
      let maxHeight = 0
      Object.keys(this.$refs).forEach((k) => {
        if (maxHeight < this.$refs[k][0].offsetHeight) {
          maxHeight = this.$refs[k][0].offsetHeight
        }
      })
      Object.keys(this.$refs).forEach((k) => {
        this.$refs[k][0].style.height = '' + `${maxHeight + 1}px`
      })
    },
    selectLanguage(lang) {
      this.selectedLanguage = lang
    },
    tryAgain() {
      this.refresh()
    },
    refresh() {
      const words = [...this.matchList]
      this.array2 = []
      this.array1 = []
      while (this.array2.length < this.numberOfMatches) {
        const w = words[Math.floor(Math.random() * words.length)]
        if (!this.array2.map((t) => t.eng).includes(w.eng)) {
          this.array2.push(w)
        }
      }
      this.array1 = this.shuffle([...this.array2])
    },
    // checkMove (e) {
    //   window.console.log('Future index: ' + e.draggedContext.futureIndex)
    // },
    endDragging(e) {
      this.dragging = false
      if (JSON.stringify(this.array2) === JSON.stringify(this.array1)) {
        Utils.dropConfetti(this.$confetti)
      }
      // this.$forceUpdate()
    },
    shuffle(array) {
      const tempArray = [...array]
      let currentIndex = array.length
      let randomIndex

      if (array.length === 2) {
        this.swap(array, 0, 1)
      } else {
        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex)
          currentIndex -= 1

          this.swap(array, currentIndex, randomIndex)
        }
      }
      if (tempArray.join('') === array.join('')) {
        this.swap(array, 0, 1)
      }
      if (tempArray.join('') === array.join('')) {
        this.swap(array, 1, 2)
      }
      return array
    },
    swap(array, from, to) {
      const temporaryValue = array[from]
      array[from] = array[to]
      array[to] = temporaryValue
    }
  },
  data: () => ({
    enabled: true,
    array2: [],
    array1: [],
    dragging: false
  })
}
</script>
<style>
.correctWord {
  font: normal 20px 'Lucida Sans Unicode', 'Lucida Grande', 'Arial Unicode MS',
    sans-serif;
}

.word {
  text-shadow: 0 1px 1px #eee;
  font: normal 'Lucida Sans Unicode', 'Lucida Grande', 'Arial Unicode MS',
    sans-serif;
  border: 1px solid #ddd;

  background: white;
  opacity: 1 !important;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

.word-drgabble {
  cursor: move;
}
.selectedLevel {
  text-decoration: underline;
}
.notSelectedLevel {
  text-decoration: none;
}
.ghost {
  opacity: 0.1;
  background: var(--v-primary-lighten5);
  color: var(--v-primary-lighten5);
}
</style>
